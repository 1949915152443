import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'cjp-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss'],
})
export class TextareaFieldComponent implements OnInit {
  @Input()
  public title: string;

  required: FormGroup;

  @Input()
  readonly: boolean;

  @Input()
  public formFieldName: string;

  @Input() parent: FormGroup;

  constructor() {}

  ngOnInit() {
    if (this.parent && this.parent.get(this.formFieldName)) {
      const validator = this.parent.get(this.formFieldName).validator;

      if (validator) {
        this.required = validator({} as AbstractControl).required;
      }
    }
  }
}
