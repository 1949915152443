import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cjp-overview-field',
  templateUrl: './overview-field.component.html',
  styleUrls: ['./overview-field.component.scss'],
})
export class OverviewFieldComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  icon: string;

  constructor() {}

  ngOnInit() {}
}
