import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cjp-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input()
  hide: boolean;

  @Input()
  role: string = 'info';

  constructor() {}

  ngOnInit() {}
}
