import { LayoutConfigService } from './../../core/_base/layout/services/layout-config.service';
import { Component, OnInit } from '@angular/core';
import * as objectPath from 'object-path';

@Component({
  selector: 'cjp-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  backgroundLogo: string;

  constructor(private layoutConfigService: LayoutConfigService) {
    const loaderConfig = layoutConfigService.getConfig('self.body');
    this.backgroundLogo = `url('${objectPath.get(
      loaderConfig,
      'background-image'
    )}')`;
  }

  ngOnInit() {}
}
